<template>
  <div>
    <form
      v-if="isFbWhatsappOnboardingEnabled && procedure === 'automated'"
      class="flex flex-wrap mx-0"
    >
      <div
        class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%] pb-4 text-sm text-slate-600 dark:text-slate-300"
      >
        Nosso processo de registro e autorização para o canal WhatsApp é
        realizado com toda a segurança e privacidade pela própria API do
        Facebook, basta seguir um dos modos abaixo:
        <br /><br />
        <span class="text-xs">
          <b>1.</b> Modo Automático: basta seguir o passo-a-passo e sua caixa
          estará configurada, não exige conhecimento técnico, sendo o método
          mais indicado por nós.
          <br />
          <b>2.</b> Modo Manual: exige conhecimento técnico e é necessário
          informar os dados da integração de acordo com a plataforma para
          desenvolvedores do Facebook. <br /><br />
          - Caso não abra a janela do Facebook, autorize a abertura de popups em
          seu navegador.
          <br />
          - Siga o passo a passo, criando um novo perfil ou selecione um já
          existente, se o número já estiver registrado em outro provedor ele
          será migrado pelo próprio Facebook.
          <br />
          - Não feche a janela até finalizar o processo. Apenas ao confirmar
          pelo botão, na última tela o processo será validado.
          <br /><br />
          <span class="text-white text-sm">
            Primeiro informe um nome para identificar a sua Caixa de Entrada,
            <br />pode ser alterado depois. Então clique no botão para iniciar.
          </span>
        </span>
      </div>
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.inboxName.$error }">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.LABEL') }}
          <input
            v-model.trim="inboxName"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.PLACEHOLDER')"
            @blur="$v.inboxName.$touch"
          />
          <span v-if="$v.inboxName.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.ERROR') }}
          </span>
        </label>
      </div>
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <input v-model.trim="phoneNumber" type="hidden" />
        <input v-model.trim="phoneNumberId" type="hidden" />
        <input v-model.trim="businessAccountId" type="hidden" />
        <input v-model.trim="apiKey" type="hidden" />
        <woot-button
          @click.prevent="
            () => {
              launchWhatsAppSignup();
            }
          "
        >
          <span> Iniciar autorização e registro automático </span>
        </woot-button>
        <br />
        <woot-button
          class="mt-8"
          style="background: #697076"
          @click.prevent="
            () => {
              procedure = 'manual';
            }
          "
        >
          <span class="text-xs"> Acessar o Cadastro Manual (avançado) </span>
        </woot-button>
      </div>
    </form>

    <cloud-whatsapp v-if="procedure === 'manual'" />

    <form
      v-if="isFbWhatsappOnboardingEnabled && procedure !== 'automated'"
      class="flex flex-wrap mx-0"
    >
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <woot-button
          class="mt-8"
          style="background: #697076"
          @click.prevent="
            () => {
              procedure = 'automated';
            }
          "
        >
          <span class="text-xs">
            Voltar ao Cadastro Automático (passo a passo)
          </span>
        </woot-button>
      </div>
    </form>

    <form v-if="procedure === 'qrcode'" class="flex flex-wrap mx-0">
      <div
        class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%] pb-4 text-sm text-slate-600 dark:text-slate-300"
      >
        TODO
      </div>
    </form>

    <form v-if="!procedure" class="flex flex-wrap mx-0">
      <div
        class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%] pb-4 text-sm text-slate-600 dark:text-slate-300"
      >
        Carregando...
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
// NUUV: not using these validations for embedded onboard
// import { isPhoneE164OrEmpty, isNumber } from 'shared/helpers/Validators';
import CloudWhatsapp from './CloudWhatsapp.vue';

export default {
  components: {
    CloudWhatsapp,
  },
  mixins: [alertMixin],
  data() {
    return {
      procedure: null, // automated, manual, qrcode
      inboxName: '', // form
      phoneNumber: '', // automation
      phoneNumberId: '', // event: sessionInfo
      businessAccountId: '', // webhooks
      apiKey: '', // response whatsapp_embedded_signup
    };
  },
  computed: {
    ...mapGetters({ uiFlags: 'inboxes/getUIFlags' }),
    isFbWhatsappOnboardingEnabled() {
      if (window.talkerConfig?.fbWhatsappOnboarding === '1') {
        return true;
      }
      return false;
    },
  },
  validations: {
    inboxName: { required },
    // phoneNumber: { required, isPhoneE164OrEmpty },
    phoneNumber: { required }, // using to send data for whatsapp_business_account
    apiKey: { required },
    // phoneNumberId: { required, isNumber },
    phoneNumberId: { required },
    // businessAccountId: { required, isNumber },
    businessAccountId: { required },
  },
  mounted() {
    const sessionInfoListener = event => {
      // console.debug('[DEBUG]', event); // debug
      //
      if (
        ![
          'https://facebook.com',
          'https://www.facebook.com',
          'https://web.facebook.com',
        ].includes(event.origin)
      ) {
        return;
      }
      // console.debug('[DEBUG] Event:', event); // debug
      //
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          if (data.event === 'FINISH') {
            // user finished the Embedded Signup flow
            // console.debug('[DEBUG] Finish:', data.data); // debug
            const { phone_number_id, waba_id } = data.data;
            this.businessAccountId = waba_id;
            this.phoneNumberId = phone_number_id;
            this.phoneNumber = `${window.talkerConfig.fbBusinessId}+${window.talkerConfig.fbAppId}`;
          } else {
            // const { current_step } = data.data;
            // NUUV: user canceled the Embedded Signup flow
            // console.debug('[DEBUG] Cancel:', current_step, data.data); // debug
          }
        }
      } catch {
        // event.data is not JSON
        // console.debug('[DEBUG] !JSON', event.data); // debug
      }
    };
    //
    this.$nextTick(async () => {
      this.procedure = window.talkerConfig?.fbWhatsappOnboarding
        ? 'automated'
        : 'manual';
      window.addEventListener('message', sessionInfoListener);
    });
  },
  methods: {
    // https://developers.facebook.com/docs/whatsapp/embedded-signup
    // https://developers.facebook.com/docs/whatsapp/embedded-signup/overview
    // https://developers.facebook.com/docs/whatsapp/embedded-signup/getting-started
    // https://developers.facebook.com/docs/whatsapp/embedded-signup/embed-the-flow
    // https://developers.facebook.com/docs/whatsapp/embedded-signup/embed-the-flow#after-business-completes-signup-flow
    async launchWhatsAppSignup() {
      if (!window.FB) {
        // console.error('[DEBUG] Facebook SDK not loaded.'); // debug
        return;
      }
      // validate inboxName only
      this.$v.$touch();
      if (!this.inboxName) {
        return;
      }
      this.businessAccountId = '';
      this.phoneNumberId = '';
      this.phoneNumber = '';
      this.apiKey = '';
      //
      window.FB.login(
        response => {
          // console.debug('[DEBUG]', response); // debug
          //
          const value = response?.authResponse || null;
          if (
            window.fb_app?.authResponse &&
            Array.isArray(window.fb_app?.authResponse)
          ) {
            window.fb_app.authResponse.push(value);
          } else {
            window.fb_app = {
              ...window.fb_app,
              authResponse: [value],
            };
          }
          //
          if (response.authResponse?.code) {
            this.apiKey = response.authResponse.code; // code to call the debug_token API and get the shared resources
            // TODO: solution to store full authResponse data
            // this does not work, max 255 chars
            this.phoneNumber += `+userID_${response.authResponse.userID}+expiresIn${response.authResponse.expiresIn}`;
            this.$nextTick(async () => {
              this.createChannel();
            });
          } else {
            // console.debug('User cancelled login or did not fully authorize.');
          }
        },
        {
          config_id: window.talkerConfig.fbLoginConfigId, // configuration ID obtained in the previous step goes here
          response_type: 'code', // must be set to 'code' for System User access token
          override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
          extras: {
            feature: 'whatsapp_embedded_signup',
            sessionInfoVersion: 2, //  Receive Session Logging Info
            // featureType: 'only_waba_sharing', // Bypass phone number selection
          },
        }
      );
    },
    async createChannel() {
      const channel = {
        type: 'whatsapp',
        provider: 'whatsapp_cloud',
        phone_number: this.phoneNumber.toString(),
        provider_config: {
          business_account_id: this.businessAccountId.toString(), // waba_id
          phone_number_id: this.phoneNumberId.toString(), // phone_number_id
          api_key: this.apiKey, // onboard_code
        },
      };
      //
      // console.debug('[DEBUG] createChannel()', channel); // debug
      //
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        const whatsappChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: this.inboxName,
            channel,
          }
        );
        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: whatsappChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.WHATSAPP.ERROR'));
      }
    },
  },
};
</script>
