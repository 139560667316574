<template>
  <div
    class="border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"
  >
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.WHATSAPP.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.WHATSAPP.DESC')"
    />
    <!--
    <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
      <label>
        {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.LABEL') }}
        <select v-model="provider">
          <option value="whatsapp_cloud">
            {{ providerName }}
          </option>
          <option value="whatsapp_cloud">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.WHATSAPP_CLOUD') }}
          </option>
          <option value="twilio">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.TWILIO') }}
          </option>
        </select>
      </label>
    </div>
    -->
    <!--
    <twilio v-if="provider === 'twilio'" type="whatsapp" />
    <three-sixty-dialog-whatsapp v-else-if="provider === '360dialog'" />
    <cloud-whatsapp v-else />
    -->
    <br />
    <provider-whatsapp />
  </div>
</template>

<script>
import PageHeader from '../../SettingsSubPageHeader.vue';
// import Twilio from './Twilio.vue';
// import ThreeSixtyDialogWhatsapp from './360DialogWhatsapp.vue';
// import CloudWhatsapp from './CloudWhatsapp.vue';
import ProviderWhatsapp from './ProviderWhatsapp.vue';

export default {
  components: {
    PageHeader,
    // Twilio,
    // ThreeSixtyDialogWhatsapp,
    // CloudWhatsapp,
    ProviderWhatsapp,
  },
  data() {
    return {
      provider: 'whatsapp_cloud',
      providerName: 'Selecionando Provedor...',
    };
  },
  mounted() {
    this.$nextTick(async () => {
      this.providerName =
        window.talkerConfig?.fbWhatsappOnboardingName ||
        'WhatsApp Business API';
    });
  },
};
</script>
