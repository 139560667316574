export default {
  computed: {
    hostURL() {
      return window.talkerConfig.hostURL;
    },
    vapidPublicKey() {
      return window.talkerConfig.vapidPublicKey;
    },
    enabledLanguages() {
      return window.talkerConfig.enabledLanguages;
    },
    isEnterprise() {
      return window.talkerConfig.isEnterprise === 'true';
    },
    enterprisePlanName() {
      // returns "community" or "enterprise"
      return window.talkerConfig?.enterprisePlanName;
    },
  },
};
