<template>
  <div
    class="border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"
  >
    <page-header
      header-title="Canal do WhatsaApp QR"
      header-content="Atenda seus clientes via WhatsApp."
    />
    <form class="mx-0 flex flex-wrap" @submit.prevent="createChannel()">
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.channelName.$error }">
          {{ $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.LABEL') }}
          <input
            v-model.trim="channelName"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.PLACEHOLDER')
            "
            @blur="$v.channelName.$touch"
          />
          <span v-if="$v.channelName.$error" class="message">{{
            $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.ERROR')
          }}</span>
        </label>
      </div>

      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.phoneNumber.$error }">
          {{
            $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.LABEL') +
            ' (ex: +551100000000)'
          }}
          <input
            v-model.trim="phoneNumber"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.PLACEHOLDER')
            "
            @blur="$v.phoneNumber.$touch"
          />
          <span v-if="$v.phoneNumber.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.ERROR') }}
          </span>
        </label>
      </div>

      <div class="w-full">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.WHATSAPP.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import Api from './Api.vue'; // Import the Api component as base
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import { isPhoneE164OrEmpty } from 'shared/helpers/Validators';

export default {
  extends: Api, // Extend the base component
  data() {
    return {
      ...this.$data,
      // new data
      phoneNumber: null,
    };
  },
  computed: {
    ...Api.computed,
    // new computed
    accountId() {
      return this.$store.getters['auth/getCurrentAccountId'];
    },
  },
  validations: {
    ...Api.validations,
    // new validations
    webhookUrl: { required },
    phoneNumber: { required, isPhoneE164OrEmpty },
  },
  methods: {
    ...Api.methods,
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const instance_name = `${this.accountId}_${this.phoneNumber
          .replace('+', '')
          .trim()}`;
        const apiChannel = await this.$store.dispatch('inboxes/createChannel', {
          name: this.channelName,
          channel: {
            type: 'api',
            webhook_url: instance_name,
            additional_attributes: {
              sub_type: 'whatsappqrc',
              phone_number: this.phoneNumber,
              instance_name,
            },
          },
        });

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: apiChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.API_CHANNEL.API.ERROR_MESSAGE'));
      }
    },
    /*
    newMethod() {
      // Add new methods
      console.log('This is a new method.');
    },
    */
  },
};
</script>
